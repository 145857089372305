exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ausstellungen-js": () => import("./../../../src/pages/ausstellungen.js" /* webpackChunkName: "component---src-pages-ausstellungen-js" */),
  "component---src-pages-auszeichnungen-js": () => import("./../../../src/pages/auszeichnungen.js" /* webpackChunkName: "component---src-pages-auszeichnungen-js" */),
  "component---src-pages-capriccio-js": () => import("./../../../src/pages/capriccio.js" /* webpackChunkName: "component---src-pages-capriccio-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-das-fruehwerk-js": () => import("./../../../src/pages/das-fruehwerk.js" /* webpackChunkName: "component---src-pages-das-fruehwerk-js" */),
  "component---src-pages-dialog-mit-den-verehrten-alten-meistern-js": () => import("./../../../src/pages/dialog-mit-den-verehrten-alten-meistern.js" /* webpackChunkName: "component---src-pages-dialog-mit-den-verehrten-alten-meistern-js" */),
  "component---src-pages-grafik-js": () => import("./../../../src/pages/grafik.js" /* webpackChunkName: "component---src-pages-grafik-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lebenslauf-js": () => import("./../../../src/pages/lebenslauf.js" /* webpackChunkName: "component---src-pages-lebenslauf-js" */),
  "component---src-pages-lebensstationen-js": () => import("./../../../src/pages/lebensstationen.js" /* webpackChunkName: "component---src-pages-lebensstationen-js" */),
  "component---src-pages-malerei-js": () => import("./../../../src/pages/malerei.js" /* webpackChunkName: "component---src-pages-malerei-js" */),
  "component---src-pages-nachruf-js": () => import("./../../../src/pages/nachruf.js" /* webpackChunkName: "component---src-pages-nachruf-js" */),
  "component---src-pages-pflaster-js": () => import("./../../../src/pages/pflaster.js" /* webpackChunkName: "component---src-pages-pflaster-js" */),
  "component---src-pages-publikationen-js": () => import("./../../../src/pages/publikationen.js" /* webpackChunkName: "component---src-pages-publikationen-js" */),
  "component---src-pages-vernissage-bei-rubrecht-contemporary-in-wiesbaden-js": () => import("./../../../src/pages/vernissage-bei-rubrecht-contemporary-in-wiesbaden.js" /* webpackChunkName: "component---src-pages-vernissage-bei-rubrecht-contemporary-in-wiesbaden-js" */),
  "component---src-pages-verzeichnis-js": () => import("./../../../src/pages/verzeichnis.js" /* webpackChunkName: "component---src-pages-verzeichnis-js" */),
  "component---src-pages-werke-zur-deutschen-literatur-js": () => import("./../../../src/pages/werke-zur-deutschen-literatur.js" /* webpackChunkName: "component---src-pages-werke-zur-deutschen-literatur-js" */),
  "component---src-templates-grafik-js": () => import("./../../../src/templates/Grafik.js" /* webpackChunkName: "component---src-templates-grafik-js" */),
  "component---src-templates-malerei-js": () => import("./../../../src/templates/malerei.js" /* webpackChunkName: "component---src-templates-malerei-js" */)
}

